#clinicos__image-slider::-webkit-scrollbar {
  display: none !important;
}

#clinicos__image-slider {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

input[type="file"]::file-selector-button {
  width: inherit;
  height: inherit;
}

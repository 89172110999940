#upload-image__outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(196, 198, 205, 0.3);
  border: 1px solid #e5e5e5;
  border-radius: 0.75rem;
  aspect-ratio: 1;
  gap: 10px;
  width: 100%;
}

#upload-image__btn {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  min-height: 46px;
  width: 80%;
  background: #00b7f4;
  border-radius: 80px;
  position: relative;
}

#upload-image__input {
  position: absolute;
  inset: 0;
  opacity: 0;
  cursor: pointer;
}

#upload-image__span {
  color: #ffffff;
  font-family: Urbanist700, "Times New Roman", Times, serif;
  text-align: center;
  font-size: 1rem;
}

#upload-image__img {
  width: 53%;
}

#upload-image__upload-icon {
  margin-top: 5px;
}
